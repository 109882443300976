import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { auth } from "./Firebase/Firebase";
import { createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import LogoActive from '../images/logo-dark-green.svg';

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  // Check if user is already logged in when component mounts
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, redirect to profile page
        navigate(`/app/profile`);
      }
      // No need to do anything if there's no user
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const validatePassword = () => {
    let isValid = true;
    if (password !== "" && confirmPassword !== "") {
      if (password !== confirmPassword) {
        isValid = false;
        setError("Passwords does not match");
      }
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setError("");
      if (validatePassword()) {
        await createUserWithEmailAndPassword(auth, email, password);
        navigate(`/app/profile`);
      }
    } catch (err) {
      setError(err.message);
    }
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  return (


    <div title="Register" className="flex items-center justify-center">
      <div className="container mx-auto">
        <div className="py-5 flex items-center justify-center mb-20">
          <div className=" bg-lightGreen p-10 rounded-lg shadow-lg max-w-md w-full">

            {/* <div className="mb-7">
              <img src={LogoActive} className="w-4/6" />
              <h3 className=" text-gameOnGreen text-md font-semibold">Connect with verified mental health experts</h3>
            </div> */}

            {error && <div className="auth__error bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}
            
            {/* <form onSubmit={handleSubmit} className="mb-4">
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gameOnDarkGreen mb-2">Email address</label>
                <input
                  className="appearance-none p-4 rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gameOnLightGreen"
                  type="email"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="E-mail Address"
                />
              </div>
              <div className="mb-6">
              <label htmlFor="email" className="block text-sm font-medium text-gameOnDarkGreen mb-2">Password</label>
                <input
                  className="appearance-none p-4 rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gameOnLightGreen"
                  type="password"
                  name="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
              <div className="mb-6">
              <label htmlFor="email" className="block text-sm font-medium text-gameOnDarkGreen mb-2">Password</label>
                <input
                  className="appearance-none p-4 rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gameOnLightGreen"
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                />
              </div>
              <div className="flex items-center justify-between mb-5">
                <button className="inline-block px-14 py-5 leading-none text-white font-outfit font-medium w-full text-base rounded-3xl hover:border-transparent bg-gameOnGreen hover:bg-gameOnLightGreenActive lg:mt-0" type="submit">
                  Register
                </button>
              </div>
            </form> */}

            <div className="text-center text-sm">
              <Link to="/app/reset" className="inline-block align-baseline font-bold text-gameOnDarkGreen hover:text-gameOnDarkGreenActive">Forgot Password</Link>
              <br />
              <span>Already have an account? <Link to="/app/login" className="inline-block align-baseline font-bold text-gameOnDarkGreen hover:text-gameOnDarkGreenActive">Login</Link> now. </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Register;