import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { auth } from "./Firebase/Firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import LogoActive from '../images/logo-dark-green.svg';

const Reset = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      navigate(`/app/login`);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div title="Forgot Password" className="flex items-center justify-center">
      <div className="container mx-auto">
        <div className="py-5 flex items-center justify-center mb-20">
          {/* <div className="max-w-sm w-full"> */}
          <div className=" bg-lightGreen p-10 rounded-lg shadow-lg max-w-md w-full">

            {/* <h2 className=" text-gameOnDarkGreen text-md mb-5">Enter your email and password to Log in.</h2> */}

            <div className="mb-7">
              <img src={LogoActive} className="w-4/6" />
              <h3 className=" text-gameOnGreen text-md font-semibold">Connect with verified mental health experts</h3>
            </div>

            {error && <div className="auth__error bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}

            <form onSubmit={handleSubmit} className="mb-4">
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gameOnDarkGreen mb-2">Email address</label>
                <input
                  className="appearance-none p-4 rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gameOnLightGreen"
                  type="email"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="E-mail Address"
                />
              </div>
              <div className="flex items-center justify-between mb-5">
                <button className="inline-block px-14 py-5 leading-none text-white font-outfit font-medium w-full text-base rounded-3xl hover:border-transparent bg-gameOnGreen hover:bg-gameOnLightGreenActive lg:mt-0" type="submit">
                  Forgot Password
                </button>
              </div>
            </form>
            <div className="text-center text-sm">
              Already have an account? <Link to="/app/login" className="inline-block align-baseline font-bold text-gameOnDarkGreen hover:text-gameOnDarkGreenActive">Login</Link> now.
              <br />
              {/* <span>Don't have an account? <Link to="/app/register" className="inline-block align-baseline font-bold text-gameOnDarkGreen hover:text-gameOnDarkGreenActive">Register</Link> now.</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
