import React, { useState, useEffect } from "react";
import { useAuthValue } from "./Auth/AuthContext";
import { getDatabase, ref, get } from "firebase/database"; // Import the required Firebase modules
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Profile = () => {
  const { currentUser } = useAuthValue();
  const [user, setUser] = useState(null);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [providers, setProviders] = useState([]);
  const [users, setUsers] = useState([]);
  const [meetingCounts, setMeetingCounts] = useState({}); // State to store meeting counts

  useEffect(() => {
    const providersRef = ref(getDatabase(), 'PROD/providers');
    const usersRef = ref(getDatabase(), 'PROD/users');
    const bookingsRef = ref(getDatabase(), 'PROD/bookings'); // Reference to the bookings in your database

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    };

    get(providersRef).then((snapshot) => {
      if (snapshot.exists()) {
        const providersData = snapshot.val();
        const providersList = Object.keys(providersData)
          .map((key) => ({
            name: providersData[key].name,
            email: providersData[key].email,
            img: providersData[key].img,
            verified: providersData[key].verified ? 'Verified' : 'Not Verified',
          }))
          .filter(provider => provider.email && !provider.email.includes('@yopmail.com') && !provider.email.includes('@mailinator.com') && !/^dev\d+/.test(provider.email));
        setProviders(providersList);
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error('Error fetching providers:', error);
    });

    get(usersRef).then((snapshot) => {
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const processedUsers = Object.values(usersData).map(user => ({
          name: user.full_name,
          img: user.img || null,
          dob: user.dob,
          email: user.email,
          createdOn: formatDate(user.created_on),
          lastActive: formatDate(user.signed_on),
          location: user.state,
          isVerified: user.verified ? 'Verified' : 'Not Verified'
        }))
        .filter(user => user.email && !user.email.includes('@yopmail.com') && !user.email.includes('@mailinator.com') && !/^dev\d+/.test(user.email));
        setUsers(processedUsers);
      } else {
        console.log("No users available");
      }
    }).catch((error) => {
      console.error('Error fetching users:', error);
    });

    get(bookingsRef).then((snapshot) => {
      if (snapshot.exists()) {
        const bookingsData = snapshot.val();
        const counts = {};

        Object.values(bookingsData).forEach((booking) => {
          const { dateTime, meetings, provider_email } = booking;
          if (new Date(dateTime) >= startDate && new Date(dateTime) <= endDate && meetings) {
            Object.values(meetings).forEach((meeting) => {
              if (meeting.is_completed) {
                counts[provider_email] = (counts[provider_email] || 0) + 1;
              }
            });
          }
        });

        setMeetingCounts(counts);
      } else {
        console.log("No bookings available");
      }
    }).catch((error) => {
      console.error('Error fetching bookings:', error);
    });

  }, [startDate, endDate]);

  const renderProviderImage = (provider) => {
    if (provider.img) {
      return <img src={provider.img} alt={provider.name} className="h-10 w-10 rounded-full" />;
    } else {
      const initial = provider.email ? provider.email.charAt(0).toUpperCase() : '?';
      return (
        <div className="rounded-full bg-gameOnDarkGreen text-white text-center h-10 w-10 py-2">
          <div className="text-md">{initial}</div>
        </div>
      );
    }
  };

  const renderUserImage = (user) => {
    if (user.img) {
      const imageSrc = `data:image/png;base64,${user.img}`;
      return <img src={imageSrc} alt={user.name} className="h-10 w-10 rounded-full" />;
    } else {
      const initial = user.email ? user.email.charAt(0).toUpperCase() : '?';
      return (
        <div className="rounded-full bg-gameOnDarkGreen text-white text-center h-10 w-10 py-2">
          <div className="text-md">{initial}</div>
        </div>
      );
    }
  };

  
  

  return (

    <div className="container mx-auto mb-20">
        
    <div className="">
      <p>Welcome back to your profile, {currentUser.email}!</p>
    </div>
    
    <br />

    <Tabs className="bg-gameOnLightGreen">
      <TabList>
        <Tab>Professionals</Tab>
        <Tab>Users</Tab>
        <Tab>Book Reports</Tab>
      </TabList>

      <TabPanel>
        <div className="flex flex-col p-10">


          <div className="dataDetails">
            <p className="font-md">Showing <strong>{providers.length}</strong> professionals sorted by date.</p>
            <hr className="mt-5" />
          </div>
          
          <div className="dataOutput">

          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Email
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                      </tr>
                    </thead>

                    
                    <tbody className="bg-white divide-y divide-gray-200">
                      {providers.map((provider, index) => (
                        provider.email && (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {renderProviderImage(provider)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{provider.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{provider.email}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{provider.verified}</td>
                          </tr>
                        )
                      ))}
                    </tbody>


                  </table>
                </div>
              </div>
            </div>
          </div>

          </div>

        </div>
      </TabPanel>

      <TabPanel>
        <div className="flex flex-col p-10">

          <div className="dataOutput">

          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Email
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Location
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          DoB
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Joined
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Last Active
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                      </tr>
                    </thead>

                    
                    <tbody className="bg-white divide-y divide-gray-200">
                      {users.map((user, index) => (
                        user.email && (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {renderUserImage(user)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.location}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.dob}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.createdOn}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.lastActive}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.isVerified}</td>
                          </tr>
                        )
                      ))}
                    </tbody>


                  </table>
                </div>
              </div>
            </div>
          </div>




          </div>
        </div>
      </TabPanel>

        {/* ... other TabList and TabPanels ... */}

        <TabPanel>
          <div className="flex flex-col p-10">
            <div className="">
              <label htmlFor="date-range" className="mb-2 text-base">Select a date range:</label>
              <DatePicker
                id="date-range"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  const [start, end] = update;
                  setStartDate(start);
                  setEndDate(end);
                }}
                className="form-input block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:via-gameOnLighterGreen focus:border-gameOnDarkGreenActive sm:text-sm"
              />
              <div className="my-5 w-full inline-block mx-auto text-center">
                {/* <button
                  className="inline-block w-full px-20 py-5 leading-none text-white font-outfit font-medium text-base rounded-3xl hover:border-transparent lg:mt-0 bg-gameOnGreen hover:bg-gameOnLightGreenActive"
                >
                  Generate Report
                </button> */}
              </div>
            </div>

            <div className="shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Completed Sessions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(meetingCounts).map(([email, count]) => (
                    <tr key={email}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{email}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>

    </Tabs>
    
  </div>
  )
}

export default Profile
