import React from "react"
import { Link, navigate } from "@reach/router"
import { useAuthValue } from "../Auth/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../Firebase/Firebase";

export default () => {

  const { currentUser } = useAuthValue();

  let details
  if (!currentUser) {
    details = (
      
      <div className="container mx-auto py-10">
        <div className="text-center">
            <h1 className="text-3xl font-bold mb-2 text-gameOnDarkGreen">
            Welcome
            </h1>
            <p className="text-lg font-bold mb-2 text-gameOnGreen my-5">
            Enter your email and password to {` `} <Link to="/app/login">log in</Link>.
            </p>
        </div>
      </div>
    )
  } else {
    details = (
      <div className="container mx-auto py-10">
        Logged in as {currentUser.email}
        {` `}
        <a
          href="/"
          onClick={event => {
            event.preventDefault()
            signOut(auth)
            navigate(`/app/login`)
          }}
        >
          log out
        </a>
      </div>
    )
  }

  return <div className="">{details}</div>
}
